const TokenKey = 'new-access-token'
const RefreshTokenKey = 'new-refresh-token'
const User = 'new-user'
const DataType = 'dataType'
const Content = 'content'
const Datetime = 'datetime'
const ProjectIndexKey = 'project-index'

import func from '@/util/func';

export function getToken(type) {
    let _json = localStorage.getItem(TokenKey);
    if(!func.isJson(_json)){
        removeToken();
    }
    if(!type) return (JSON.parse(localStorage.getItem(TokenKey))||{}) [Content]
    return JSON.parse(localStorage.getItem(TokenKey)||'{}')
}

export function setToken(token) {
    //IOS设备可能会出现错误，需要先删除
    let tokenObj = {
        [DataType]: typeof (token),
        [Content]: token,
        [Datetime]: new Date().getTime()
    }
    removeToken();
    return localStorage.setItem(TokenKey, JSON.stringify(tokenObj));
}

export function getRefreshToken() {
  return localStorage.getItem(RefreshTokenKey)
}

export function setRefreshToken(token) {
    removeRefreshToken();
  return localStorage.setItem(RefreshTokenKey, token);
}

export function removeToken() {
    return localStorage.removeItem(TokenKey)
}

export function removeRefreshToken() {
  return localStorage.removeItem(RefreshTokenKey)
}

export function setUser(user) {
    console.log(user,'user')
    return localStorage.setItem(User,JSON.stringify(user))
}

export function removeUser() {
    return localStorage.removeItem(User)
}

export function getUser() {
    return JSON.parse(localStorage.getItem(User))
}

export function setProjectIndex(index) {
    return localStorage.setItem(ProjectIndexKey,JSON.stringify(index))
}

export function removeProjectIndex() {
    return localStorage.removeItem(ProjectIndexKey)
}

export function getProjectIndex() {
    return JSON.parse(localStorage.getItem(ProjectIndexKey))
}



