import {Base64} from "js-base64";
import website from "../config/website";
import {getToken} from "./auth";
import {getToken as getZyptToken} from "./auth_zyxt";

/**
 * 通用工具类
 */
export default class func {
    /**
     * 不为空
     * @param val
     * @returns {boolean}
     */
    static notEmpty(val) {
        return !this.isEmpty(val);
    }

    /**
     * 拼接代理地址
     * @param url
     * @returns {string|string|*}
     */
    static getProxyUrl(url) {
        if (!url || url.toLowerCase().startsWith("http")) {
            return url;
        }
        const  _url = url.toLowerCase();
        if (!url || _url.startsWith("http")) {
            return url;
        }
        if(_url.startsWith('uploadotherall')){
            return (website.propsHttpPrefix || '') + url;
        }
        let regExp = /^\w.*\.(png|jpg|gif|jpeg)/g;
        if(_url.match(regExp)){
            return require('@/assets/image/static/' + url)
        }else {
            return url;
        }
    }

    /**
     * 格式化空值
     * @param val 需要格式化的value
     * @param defaultVal 如果为空，要显示的默认值
     * @param nullValArr 其他认为为空的值，如[-1]等
     * @returns {*}
     */
    static formatNullVal(val, defaultVal,...nullValArr){
        const _nullValArr = nullValArr || []
        if(!val || _nullValArr.includes(val)){
            return defaultVal;
        }
        return val;
    }


    /**
     * 是否为定义
     * @param val
     * @returns {boolean}
     */
    static isUndefined(val) {
        return val === null || typeof val === 'undefined';
    }

    /**
     * 为空
     * @param val
     * @returns {boolean}
     */
    static isEmpty(val) {
        if (
            val === null ||
            typeof val === 'undefined' ||
            (typeof val === 'string' && val === '' && val !== 'undefined')
        ) {
            return true;
        }
        return false;
    }

    /**
     * 强转int型
     * @param val
     * @param defaultValue
     * @returns {number}
     */
    static toInt(val, defaultValue) {
        if (this.isEmpty(val)) {
            return defaultValue === undefined ? -1 : defaultValue;
        }
        const num = parseInt(val, 0);
        return Number.isNaN(num) ? (defaultValue === undefined ? -1 : defaultValue) : num;
    }

    /**
     * Json强转为Form类型
     * @param obj
     * @returns {FormData}
     */
    static toFormData(obj) {
        const data = new FormData();
        Object.keys(obj).forEach(key => {
            data.append(key, Array.isArray(obj[key]) ? obj[key].join(',') : obj[key]);
        });
        return data;
    }

    /**
     * date类转为字符串格式
     * @param date
     * @param format
     * @returns {null}
     */
    static format(date, format = 'YYYY-MM-DD HH:mm:ss') {
        return date ? date.format(format) : null;
    }

    /**
     * 根据逗号联合
     * @param arr
     * @returns {string}
     */
    static join(arr) {
        return arr ? arr.join(',') : '';
    }

    /**
     * 根据逗号分隔
     * @param str
     * @returns {string}
     */
    static split(str) {
        return str ? String(str).split(',') : '';
    }

    static isJson(str) {
        if (typeof str == 'string') {
            try {
                let obj = JSON.parse(str);
                if (typeof obj === 'object' && obj) {
                    return true;
                } else {
                    return false;
                }
            } catch (e) {
                return false;
            }
        }
        return false;
    }

    /**
     * 获取header
     * @returns object
     */
    static getHeaders() {
        return {
            'Authorization': `Basic ${Base64.encode(`${website.clientId}:${website.clientSecret}`)}`,
            [website.tokenHeader]: 'bearer ' + getToken()
        };
    }

    /**
     * 获取header
     * @returns object
     */
    static getZyxtHeaders() {
        return {
            'Authorization': `Basic ${Base64.encode(`${website.clientId}:${website.clientSecret}`)}`,
            [website.tokenHeader]: 'bearer ' + getZyptToken()
        };
    }

    static doPreviewFile(path) {
        if ("https:" == document.location.protocol) {
            var wwwhost;
            if (window.location.port == "") {
                wwwhost = "https://" + window.location.hostname;
            } else {
                wwwhost = "https://" + window.location.hostname + ":" + window.location.port;
            }
            var temp = wwwhost;
            if (path.endWith("jpg") || path.endWith("jpeg") || path.endWith("png") || path.endWith("bmp") || path.endWith("gif") || path.endWith("mp4")) {
                window.open("/" + path);
            } else {
                window.open('http://ood.gsjy.net/?i=14552&ssl=1&furl=' + encodeURIComponent(temp + "/" + path));
            }
        } else {
            var wwwhost;
            if (window.location.port == "") {
                wwwhost = "http://" + window.location.hostname;
            } else {
                wwwhost = "http://" + window.location.hostname + ":" + window.location.port;
            }
            var temp = wwwhost;
            if (path.endWith("jpg") || path.endWith("jpeg") || path.endWith("png") || path.endWith("bmp") || path.endWith("gif") || path.endWith("mp4")) {
                window.open("/" + path);
            } else {
                window.open('http://ood.gsjy.net/?i=14552&furl=' + encodeURIComponent(temp + "/" + path));
            }
        }
    }
}
