/**
 * 作业系统路由
 */
const Layout = () => import('@/page/layout/index')
export default [
    {
        path: '/zyxt/index',
        name: 'zyxt-index',
        component: () => import('@/views/zyxt/index/index.vue'),
        meta: {
            title: '作业系统'
        }
    },
    {
        path: '/zyxt/zy',
        name: 'work',
        redirect: '/zyxt/zy/my-index',
        component: Layout,
        children: [
            {
                path: 'add',
                name: 'work-add',
                component: () => import('@/views/zyxt/zy/add.vue'),
                meta: {
                    title: '即日作业申报'
                }
            },
            {
                path: 'my-detail',
                name: 'work-my-detail',
                component: () => import('@/views/zyxt/zy/myDetail.vue'),
                meta: {
                    title: '即日作业详情'
                }
            },
            {
                path: 'search-detail',
                name: 'work-search-detail',
                component: () => import('@/views/zyxt/zy/searchDetail.vue'),
                meta: {
                    title: '即日作业详情'
                }
            },
            {
                path: 'search-detail-show',
                name: 'work-search-detail-show',
                component: () => import('@/views/zyxt/zy/searchDetailShow.vue'),
                meta: {
                    title: '即日作业详情'
                }
            },
            {
                path: 'audit-detail',
                name: 'work-audit-detail',
                component: () => import('@/views/zyxt/zy/auditDetail.vue'),
                meta: {
                    title: '即日作业评分'
                }
            },
            {
                path: 'audit-all-detail',
                name: 'work-all-audit-detail',
                component: () => import('@/views/zyxt/zy/auditAllDetail.vue'),
                meta: {
                    title: '即日作业评分'
                }
            },
            {
                path: 'audit-detail-show',
                name: 'work-audit-detail-show',
                component: () => import('@/views/zyxt/zy/auditDetailShow.vue'),
                meta: {
                    title: '即日作业详情'
                }
            },
            {
                path: 'my-index',
                name: 'work-my-list',
                component: () => import('@/views/zyxt/zy/myList.vue'),
                meta: {
                    title: '我的即日作业'
                }
            },
            {
                path: 'search-index',
                name: 'work-search-list',
                component: () => import('@/views/zyxt/zy/searchList.vue'),
                meta: {
                    title: '即日作业列表',
                    keepAlive:true
                }
            },
            {
                path: 'audit-index',
                name: 'work-audit-list',
                component: () => import('@/views/zyxt/zy/auditList.vue'),
                meta: {
                    title: '即日作业列表',
                    keepAlive:true
                }
            },
            {
                path: 'my-exam',
                name: 'my-exam-list',
                component: () => import('@/views/zyxt/zy/question/myExams.vue'),
                meta: {
                    title: '我的问卷调查'
                }
            },
            {
                path: 'my-exam-student',
                name: 'my-exam-list-student',
                component: () => import('@/views/zyxt/zy/question/myExamsStudent.vue'),
                meta: {
                    title: '我的问卷调查'
                }
            },
            {
                path: 'exam-add',
                name: 'exam-add',
                component: () => import('@/views/zyxt/zy/question/examAdd.vue'),
                meta: {
                    title: '我的问卷调查'
                }
            },
            {
                path: 'exam-detail',
                name: 'exam-detail',
                component: () => import('@/views/zyxt/zy/question/examDetailTeacher.vue'),
                meta: {
                    title: '我的问卷调查详细'
                }
            },
            {
                path: 'question/doExam',
                name: 'exam-student',
                component: () => import('@/views/zyxt/zy/question/doExam.vue'),
                meta: {
                    title: '参与问卷调查',
                }
            },
            {
                path: 'rolesetting/rolesetting',
                name: 'rolesetting',
                component: () => import('@/views/zyxt/zy/rolesetting/rolesetting.vue'),
                meta: {
                    title: '角色设置',
                }
            },
            {
                path: 'squadGroup/squadGroup',
                name: 'squadGroup',
                component: () => import('@/views/zyxt/zy/squadGroup/squadGroup.vue'),
                meta: {
                    title: '班级分组',
                }
            },
            {
                path: 'squadGroup/editSquadGroup',
                name: 'editSquadGroup',
                component: () => import('@/views/zyxt/zy/squadGroup/editSquadGroup.vue'),
                meta: {
                    title: '班级分组',
                }
            },
            {
                path: 'dataClear/dataClear',
                name: 'dataClear',
                component: () => import('@/views/zyxt/zy/dataClear/dataClear.vue'),
                meta: {
                    title: '数据清除',
                }
            },
            {
                path: 'tj/tj1',
                name: 'zy-tj1',
                component: () => import('@/views/zyxt/zy/tj/tj1.vue'),
                meta: {
                    title: '单科多班统计',
                }
            },
            {
                path: 'tj/tj2',
                name: 'zy-tj2',
                component: () => import('@/views/zyxt/zy/tj/tj2.vue'),
                meta: {
                    title: '单班单科统计',
                }
            }
        ]
    }
]
