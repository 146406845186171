/**
 * 全站http配置
 *
 * axios参数说明
 * isSerialize是否开启form表单提交
 * isToken是否需要token
 */
import axios from 'axios';
import router from '@/router/router';
import {serialize} from '@/util/util';
import {getToken,getProjectIndex} from '@/util/auth';
import {getToken as getTokenZyxt} from '@/util/auth_zyxt';
import {getToken as getTokenZjpj} from '@/util/auth_zhpj';
import {Toast, Notify} from 'vant';
import website from '@/config/website';
import {Base64} from 'js-base64';
import 'nprogress/nprogress.css';
import NProgress from 'nprogress';
import {getStore} from '@/util/store'
Toast.allowMultiple();
//默认超时时间
axios.defaults.timeout = 30000;
//返回其他状态码
axios.defaults.validateStatus = function (status) {
    return status >= 200 && status <= 500;
};
//跨域请求，允许保存cookie
axios.defaults.withCredentials = true;
// NProgress 配置
NProgress.configure({
    showSpinner: false
});
//http request拦截
axios.interceptors.request.use((config) => {
    //开启 progress bar
    NProgress.start();
    const meta = (config.meta || {});
    const isToken = meta.isToken === false;
    config.headers['Authorization'] = `Basic ${Base64.encode(`${website.clientId}:${website.clientSecret}`)}`;
    //让每个请求携带token
    const projectIndex =  getProjectIndex();
    let _token;
    if(projectIndex === 0){
        _token = getToken()
    }else if(projectIndex === 1){
        _token = getTokenZyxt()
    }else if(projectIndex === 2){
        _token = getTokenZjpj()
    }


    if (_token && !isToken) {
        config.headers[website.tokenHeader] = 'bearer ' + _token
    }
    //headers中配置text请求
    if (config.text === true) {
        config.headers["Content-Type"] = "text/plain";
    }
    //headers中配置serialize为true开启序列化
    if (config.method === 'post' && meta.isSerialize === true) {
        config.data = serialize(config.data);
    }
    // header 里面放入当前角色
    let userInfo = getStore({name: 'zyxt-userInfo'})
    if (userInfo){
        config.headers["zyxt-current-role-id"] = userInfo.zyxt_current_role_id;
        config.headers["zyxt-current-role-name"] = userInfo.zyxt_current_role_name;
        config.headers["current-type"] = userInfo.current_type;
        config.headers["current-child-id"] = userInfo.current_child_id;
    }
    return config
}, error => {
    return Promise.reject(error)
});
//http response 拦截
axios.interceptors.response.use(res => {
    //关闭 progress bar
    NProgress.done();
    //获取状态码
    const status = res.data.code || res.status;
    const statusWhiteList = website.statusWhiteList || [];
    const message = res.data.msg || res.data.error_description || '未知错误';
    //如果在白名单里则自行catch逻辑处理
    if (statusWhiteList.includes(status)) return Promise.reject(res);
    //如果是401则跳转到登录页面
    const projectIndex =  getProjectIndex();
    if (status === 401) {
        router.push({name:"login"});
    }
    // 如果请求为非200否者默认统一处理
    if (status !== 200) {
        if(status !== 401){
            Notify({type: 'warning', message:`${message}`});
        }
        return Promise.reject(new Error(message))
    }
    return res;
}, error => {
    NProgress.done();
    return Promise.reject(new Error(error));
});

export default axios;
