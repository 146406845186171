/**
 * 综合素养评价路由
 */

export default [
    {
        path: '/zhpj/index',
        name: 'zhpj-index',
        component: () => import('@/views/zhpj/index/index'),
        meta: {
            title: '综合素养评价'
        }
    },
]
