<template>
  <router-view class="router-view" />
</template>

<script>
import {reactive, toRefs,onMounted,computed} from 'vue'
import {useStore} from 'vuex'
import {useRouter} from 'vue-router'
import {validatenull} from "@/util/validate"
import {calcDate} from "@/util/date.js"
import website from "@/config/website";
import {
  getToken,
  getRefreshToken
} from '@/util/auth'
import {
  getToken as getTokenZyxt,
  getRefreshToken as getRefreshTokenZyxt
} from '@/util/auth_zyxt'
import {doRefreshToken} from "@/api/login/login";

export default {
  name: 'App',
  setup() {
    const router = useRouter()
    const store = useStore()
    const state = reactive({				// reactive--监听
      transitionName: 'fade',
      refreshTime:'',
      projectIndex:computed(()=> store.getters.projectIndex),
      //刷新token锁
      refreshLock: false,
    })
    router.beforeEach((to, from) => {
      if (to.meta.index > from.meta.index) {
        state.transitionName = 'slide-left'
      } else if (to.meta.index < from.meta.index) {
        state.transitionName = 'slide-right'
      }
    })

    onMounted(() => {
      refreshToken();
    })


    // 定时检测token
    const refreshToken = () => {

      state.refreshTime = setInterval(() => {
        const projectIndex = state.projectIndex;
        if(![0,1].includes(projectIndex)){
          return;
        }
        // console.info(`projectIndex_${projectIndex}`)
        const project0 = projectIndex === 0
        const project1 = projectIndex === 1
        let token;
        if(project0){
          token = getToken(1) || {}
        }
        if(project1){
          token = getTokenZyxt(1) || {}
        }
        const date = calcDate(token.datetime, new Date().getTime());
        if (validatenull(date)) return;
        if (date.seconds >= website.tokenTime && !state.refreshLock) {
          state.refreshLock = true;
          let refreshTokenVal ;
          if(project0){
            refreshTokenVal = getRefreshToken()
          }
          if(project1){
            refreshTokenVal = getRefreshTokenZyxt()
          }
          console.info(`refreshToken_projext_${projectIndex}`)
          doRefreshToken(refreshTokenVal,website.tenantId)
              .then(() => {
                state.refreshLock = false;
              })
              .catch(() => {
                state.refreshLock = false;
              });
        }
      }, 10000);
    }


    return {
      ...toRefs(state)		//reactive在return时候需要toRefs来转换成响应式对象
    }
  },
}
</script>

<style lang="scss" scoped>
.router-view {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: 0 auto;
  -webkit-overflow-scrolling: touch;
}


</style>
<style lang="scss">
    /* nprogress style */
    #nprogress .bar {
      background: #a9ea7d !important;
    }

    /* Fancy blur effect */
    #nprogress .peg {
      box-shadow: 0 0 10px #a9ea7d, 0 0 5px #a9ea7d !important;
    }
</style>