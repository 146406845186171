// 阿里矢量图标库配置
let iconfontVersion = ['3345000_fazkir0hfc5'];
//at.alicdn.com/t/c/font_3345000_fazkir0hfc5.css
let iconfontUrl = `//at.alicdn.com/t/c/font_$key.css`;
let baseUrl = '';
let codeUrl = `${baseUrl}/code`
const env = process.env
let isProduction = false;
if (env.NODE_ENV === 'development') {
    baseUrl = ``; // 开发环境地址
} else if (env.NODE_ENV === 'production') {
    baseUrl = ``; //生产环境地址
    isProduction = true;
} else if (env.NODE_ENV === 'test') {
    baseUrl = ``; //测试环境地址
}
export {
    baseUrl,
    iconfontUrl,
    iconfontVersion,
    codeUrl,
    env,
    isProduction
}
