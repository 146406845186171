/**
 * 此处登录逻辑适用于一站式，作业系统引用的是/api/user.js，在状态管理store/modules/user
 */
import request from '@/router/axios';
import store from '../../store'
import {setToken, setRefreshToken, removeToken, removeRefreshToken,setUser,removeUser} from '@/util/auth'
import {setToken as setTokenZyxt, setRefreshToken as setRefreshTokenZyxt} from '@/util/auth_zyxt'
import {loginByUsername,loginByPhone, dingtalkLogin, getUserInfo, logout, refreshToken} from '@/api/user'
import {Toast} from 'vant';
import {
  removeChildNodeList,
  removeCurrentChild,
  removeParentNodeList
} from '@/api/system'

export const remoteValidatePhone = (phone) => request({
  url: '/api/blade-auth/oauth/validate-phone',
  method: 'get',
  params: {
    phone
  }
});

/**
 *  发送登录验证码
 * @returns
 */
export const getCaptcha = (tenantId,phone) => request({
  url: '/api/blade-auth/oauth/validate-code',
  method: 'get',
  headers: {
    'Tenant-Id': tenantId
  },
  params: {
    phone
  }
});

//根据手机验证码登录
export const doLoginByPhone = (userInfo) => {
  return new Promise((resolve, reject) => {
    loginByPhone(userInfo.tenantId, userInfo.phone, userInfo.smsCode).then(res => {
      const data = res.data;
      console.log(data)
      if (data.error_description) {
        Toast(data.error_description)
        reject(data.error_description);
      } else {
        setUser(Object.assign({},{
          id:data.user_id,
          avatar:data.avatar,
          nick_name:data.nick_name,
          type:data.type,
          typeall:data.typeall,
          role_name:data.role_name
        }));
        setToken(data.access_token);
        setRefreshToken(data.refresh_token);
        resolve(data);
      }
    }).catch(error => {
      reject(error);
    })
  })
}


//根据手机验证码登录
export const doDingtalkLogin = (userInfo) => {
  return new Promise((resolve, reject) => {
    dingtalkLogin(userInfo.tenantId, userInfo.dingtalkAuthCode).then(res => {
      const data = res.data;
      if (data.error_description) {
        Toast(data.error_description)
        reject(data.error_description);
      } else {
        setUser(Object.assign({},{
          id:data.user_id,
          avatar:data.avatar,
          nick_name:data.nick_name,
          type:data.type,
          typeall:data.typeall,
        }));

        setToken(data.access_token);
        setRefreshToken(data.refresh_token);
        resolve(data);
      }
    }).catch(error => {
      reject(error);
    })
  })
}


// 登出
export const doLogout = () => {
  return new Promise((resolve, reject) => {
    logout().then(() => {
      removeToken();
      removeRefreshToken();
      removeUser();
      removeCurrentChild();
      removeChildNodeList();
      removeParentNodeList()
      resolve();
    }).catch(error => {
      reject(error)
    })
  })
}


//刷新token
export const doRefreshToken = (refreshTokenV,tenantId) => {
  window.console.log('handle refresh token')
  const projectIndex = store.getters.projectIndex;
  if(![0,1].includes(projectIndex)){
    return;
  }
  const project0 = projectIndex === 0
  const project1 = projectIndex === 1

  return new Promise((resolve, reject) => {
    refreshToken(refreshTokenV, tenantId).then(res => {
      console.log(res)
      const data = res.data;
      if(project0){
        setToken(data.access_token);
        setRefreshToken(data.refresh_token);
      }
      if(project1){
        setTokenZyxt(data.access_token);
        setRefreshTokenZyxt(data.refresh_token);
      }
      resolve();
    }).catch(error => {
      reject(error)
    })
  })
}


